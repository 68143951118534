import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import classnames from 'classnames';
import { createDate } from 'utils/date';
import { DATE_FORMATS } from 'constants/index';
import styles from './SearchDatePicker.module.scss';

const SelectField = props => (
  <TextField
    {...props}
    InputProps={{
      disableUnderline: true,
      autoComplete: 'off',
      ...props.InputProps,
    }}
    variant="outlined"
    size="small"
  />
);

const SearchDatePicker = ({ key, label, placeholder, className, onChange, value, ...props }) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DatePicker
      className={classnames(styles.control, className)}
      key={key}
      label={label}
      inputFormat={DATE_FORMATS.FULL_DATE}
      value={value ? createDate(value, DATE_FORMATS.FULL_DATE) : createDate(createDate(), DATE_FORMATS.FULL_DATE)}
      onChange={onChange}
      renderInput={params => <SelectField label={label} placeholder={placeholder} {...params} />}
      {...props}
    />
  </LocalizationProvider>
);

export default SearchDatePicker;
