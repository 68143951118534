import React, { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { useQuery } from 'utils/react-query';
import queries from 'containers/Productions/queries';

import AutocompleteDropDown from 'components/uiLibrary/FormInputs/AutocompleteDropDown';

// TODO - will be removing this component in future
const AggregationSearchField = ({
  value,
  onChange,
  label,
  styles,
  customOptions,
  params = {},
  fullWidth = false,
  renderMenuListItem,
  trackingData = {},
}) => {
  const [isEntityFieldExpanded, setIsEntityFieldExpanded] = useState(false);

  const { data: options, refetch: fetchAggregatedData, isLoading = false } = useQuery(
    queries.getAggregationList({
      ...params,
      queryConfig: { enabled: false },
    }),
  );

  const debounceFetchData = useMemo(() => debounce(fetchAggregatedData, 300), [fetchAggregatedData]);

  const { fieldOptions, selectedValue } = useMemo(() => {
    if (customOptions?.length > 0) {
      return customOptions;
    }
    const preSelectedValue = options?.data?.data?.find(item => item?.id === Number(value?.id)) || value;
    return {
      fieldOptions: options?.data?.data?.map(item => ({ ...item, value: item?.id, label: item?.name })) || [],
      selectedValue: {
        ...preSelectedValue,
        ...(preSelectedValue?.name && { label: value?.name || preSelectedValue?.name }),
      },
    };
  }, [customOptions, options?.data?.data, value]);

  const onInputChange = useCallback(
    (event, fieldValue) => {
      if (event?.type === 'change') {
        // we need this because parent component controls the query params
        onChange({ label: fieldValue });
      }
    },
    [onChange],
  );

  useEffect(() => {
    if ((isEntityFieldExpanded && !customOptions?.length) || params.enableQuery) {
      debounceFetchData();
    }
  }, [isEntityFieldExpanded, debounceFetchData, customOptions, params]);

  return (
    <AutocompleteDropDown
      key={label}
      label={label}
      options={fieldOptions}
      className={styles}
      value={selectedValue}
      onChange={(e, fieldValue) => {
        onChange(fieldValue || '');
      }}
      onInputChange={onInputChange}
      isLoading={isEntityFieldExpanded && isLoading && !customOptions?.length}
      onOpen={() => {
        setIsEntityFieldExpanded(true);
      }}
      onClose={() => {
        setIsEntityFieldExpanded(false);
      }}
      fullWidth={fullWidth}
      renderMenuListItem={renderMenuListItem}
      trackingData={trackingData}
    />
  );
};

export default AggregationSearchField;
