import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { TP } from 'constants/index';
import { useTranslation } from 'src/i18n';
import SearchDatePicker from '../../../common/SearchDatePicker';

import styles from './DatePerformanceSearchFields.module.scss';

const DatePerformanceSearchFields = ({ state, setStartDate, setEndDate, className }) => {
  const { t } = useTranslation('NS_DISPLAY_V4');
  const fromDateSelected = useMemo(() => state.startDate || dayjs(new Date()), [state.startDate]);
  const toDateSelected = useMemo(() => state.endDate || null, [state.endDate]);

  return (
    <div className={styles.dateWrap}>
      <div className={styles.dateSelector}>
        <SearchDatePicker
          key="startDate"
          value={fromDateSelected}
          onChange={value => setStartDate(value)}
          label={t(`${TP}.m_FROM`)}
          className={className}
        />
        <SearchDatePicker
          key="endDate"
          value={toDateSelected}
          onChange={value => {
            setEndDate(value);
          }}
          label={t(`${TP}.m_TO`)}
          className={className}
        />
      </div>
      <div className={styles.dateButtons}>
        <button
          className={styles.daysButton}
          onClick={() => {
            setStartDate(dayjs());
            setEndDate(dayjs());
          }}
        >
          {t(`${TP}.m_TODAY`)}
        </button>
        <button
          className={styles.daysButton}
          onClick={() => {
            setStartDate(dayjs().add(1, 'day'));
            setEndDate(dayjs().add(1, 'day'));
          }}
        >
          {t(`${TP}.m_TOMORROW`)}
        </button>
        <button
          className={styles.daysButton}
          onClick={() => {
            setEndDate(dayjs(state.endDate).add(7, 'day'));
          }}
        >
          {t(`${TP}.X_DAYS`, { days: '+7' })}
        </button>
        <button
          className={styles.daysButton}
          onClick={() => {
            setEndDate(dayjs(state.endDate).add(14, 'day'));
          }}
        >
          {t(`${TP}.X_DAYS`, { days: '+14' })}
        </button>
        <button
          className={styles.daysButton}
          onClick={() => {
            setEndDate(dayjs(state.endDate).add(30, 'day'));
          }}
        >
          {t(`${TP}.X_DAYS`, { days: '+30' })}
        </button>
        <button
          className={styles.daysButton}
          onClick={() => {
            setEndDate(dayjs(state.endDate).add(60, 'day'));
          }}
        >
          {t(`${TP}.X_DAYS`, { days: '+60' })}
        </button>
        <button
          className={styles.daysButton}
          onClick={() => {
            setEndDate(dayjs(state.endDate).add(1, 'year'));
          }}
        >
          +1 {t(`${TP}.m_YEAR`)}
        </button>
      </div>
    </div>
  );
};

export default DatePerformanceSearchFields;
