import React, { useEffect, useMemo, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { useQuery } from 'utils/react-query';
import { Box } from '@mui/material';

import globalQueries from 'containers/Globals/queries';

import AutocompleteDropDown from 'components/uiLibrary/FormInputs/AutocompleteDropDown';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';

import { useTranslation } from 'src/i18n';
import { AGGREGATION_TYPES, LOCATION_TYPE, TP } from 'constants/index';

import classes from './LocationField.module.scss';

const FIELD_TYPE = {
  COUNTRY: 'country',
  CITY: 'city',
};

const LocationField = ({
  entityType,
  country,
  city,
  onChange,
  styles,
  filters = {},
  showCityField = false,
  hasAll = true,
  fullWidth = false,
}) => {
  const { t } = useTranslation('NS_DISPLAY_V4');
  const [countryValue, setCountryValue] = useState(null);
  const [cityValue, setCityValue] = useState(null);

  const { data: selectedCountry = country, refetch: fetchCountry } = useQuery(
    globalQueries.getLocation({
      type: LOCATION_TYPE.COUNTRY,
      filters: {
        slug: country?.slug,
        id: country?.id,
      },
      queryConfig: { enabled: false },
    }),
  );

  const { data: selectedCity = city, refetch: fetchCity } = useQuery(
    globalQueries.getLocation({
      type: LOCATION_TYPE.CITY,
      filters: {
        slug: city?.slug,
        country_id: country?.id,
        id: city?.id,
      },
      queryConfig: { enabled: false },
    }),
  );

  const { data: countries, refetch: fetchCountries, isLoading: isLoadingCountries } = useQuery(
    globalQueries.getAggregatedLocationListing({
      entityType,
      queryFilters: {
        ...filters,
        ...(country?.value && !countryValue && { country_id: country?.value }),
        aggregation_query: countryValue,
      },
      queryConfig: { enabled: false },
    }),
  );

  const { data: cities, refetch: fetchCities, isLoading: isLoadingCities } = useQuery(
    globalQueries.getAggregatedLocationListing({
      entityType,
      aggregationType: AGGREGATION_TYPES.CITY,
      queryFilters: {
        country_id: country?.value || country?.id,
        ...(city?.id && { city_id: city?.id }),
        aggregation_query: cityValue,
      },
      queryConfig: { enabled: false },
    }),
  );

  const debounceRefetchCountries = useMemo(() => debounce(fetchCountries, 300), [fetchCountries]);

  const debounceRefetchCities = useMemo(() => debounce(fetchCities, 300), [fetchCities]);

  useEffect(() => {
    if (!!country && (!country?.label || !country?.id)) {
      fetchCountry();
    }

    if (!!city && (!city?.label || !city?.id)) {
      fetchCity();
    }
  }, [country, city, fetchCountry, fetchCity]);

  useEffect(() => {
    debounceRefetchCountries();
    if (showCityField) {
      debounceRefetchCities();
    }
  }, [countryValue, cityValue, country, showCityField, debounceRefetchCountries, debounceRefetchCities]);

  const onChangeHandler = useCallback(
    (e, value, fieldType) => {
      if (onChange) {
        const cityCountry =
          value && fieldType === FIELD_TYPE.CITY ? { label: value.country?.name, id: value.country?.id } : null;
        onChange(
          e,
          fieldType === FIELD_TYPE.COUNTRY
            ? { city: null, country: value }
            : { city: value, country: cityCountry || selectedCountry },
        );
      }
      if (fieldType === FIELD_TYPE.COUNTRY) {
        setCityValue(null);
      }
    },
    [onChange],
  );

  const onInputChangeHandler = useCallback(
    (e, inputVal, fieldType) => {
      if (fieldType === FIELD_TYPE.COUNTRY) {
        setCountryValue(inputVal);
      } else {
        setCityValue(inputVal);
      }
      if (!inputVal?.trim()?.length) {
        onChangeHandler(e, null, fieldType);
      }
    },
    [onChangeHandler],
  );

  const getOptions = useCallback(
    options => {
      if (hasAll) {
        return [{ value: 0, id: 0, label: t(`${TP}.FN_FILTER_ALL`), slug: t(`${TP}.FN_FILTER_ALL`) }, ...options];
      }
      return options;
    },
    [hasAll],
  );

  const handlerClickOption = useCallback(event => {
    event.stopPropagation();
  }, []);

  const renderCityOption = (innerProps, option, state) => (
    <Box component="li" {...innerProps}>
      {option?.label && (
        <Typography>
          {option?.label} {option?.country?.name && <Typography>({option?.country?.name})</Typography>}
        </Typography>
      )}
      {option?.noOption && !isLoadingCities && state?.inputValue?.length > 0 && (
        <div
          onClick={handlerClickOption}
          onKeyDown={handlerClickOption}
          role="presentation"
          className={classes.noResult}
        >
          <SpriteIcon icon="search" size={30} className={classes.noResult__searchIcon} />
          <Typography weight="bold" size="16" className={classes.noResult__title}>
            {t(`${TP}.NO_RESULTS`)}
          </Typography>
          <Typography className={classes.noResult__info}>{t(`${TP}.ADJUST_SEARCH`)}</Typography>
        </div>
      )}
    </Box>
  );

  return (
    <>
      <AutocompleteDropDown
        label={t(`${TP}.m_COUNTRY`)}
        className={styles?.country}
        options={countries?.total > 0 ? getOptions(countries?.data) : []}
        value={selectedCountry}
        onInputChange={(e, input) => onInputChangeHandler(e, input, FIELD_TYPE.COUNTRY)}
        onChange={(e, option) => onChangeHandler(e, option, FIELD_TYPE.COUNTRY)}
        isLoading={isLoadingCountries && countryValue}
        clearOnBlur
        fullWidth={fullWidth}
      />
      {showCityField && (
        <AutocompleteDropDown
          label={t(`${TP}.m_CITY`)}
          className={styles?.city}
          options={cities?.total > 0 ? getOptions(cities?.data) : []}
          value={selectedCity}
          onInputChange={(e, input) => onInputChangeHandler(e, input, FIELD_TYPE.CITY)}
          onChange={(e, option) => onChangeHandler(e, option, FIELD_TYPE.CITY)}
          isLoading={isLoadingCities && cityValue}
          clearOnBlur
          renderMenuListItem={renderCityOption}
          fullWidth={fullWidth}
        />
      )}
    </>
  );
};

export default LocationField;
